<template>
  <b-card class="blog-edit-wrapper">
   
    <div class="text-center">
      <h4 class="mt-5">
        Update Other Course
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Course Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course Name"
                
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.link_id"
                   @input="removeSpace($event)"
               @change="checkLink"/>
                <small class="text-danger" v-if="!validLink">Not Valid</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="YouTube Link"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
                
              >
                <b-form-input
                  v-model="addCourseForm.video_link"
                  id="video_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Duration [Days]"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Duration [Days]"
                
              >
                <b-form-input
                  type="number"
                  id="duration"
                  v-model="addCourseForm.duration"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <label>Is Free Course</label>

<b-form-radio-group
  v-model="addCourseForm.is_free"
  label="saveas"
  class="mb-2"
>
  <b-form-radio value="1"> Yes </b-form-radio>
  <b-form-radio value="0"> No </b-form-radio>
</b-form-radio-group>
          <b-col cols="4" v-if="addCourseForm.is_free=='0'" >
            <b-form-group label="Price" label-for="blog-content" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Duration [Days]"
                
              >
                <b-form-input
                  type="number"
                  id="price"
                  v-model="addCourseForm.price"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">Tab image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://api.lmitac.com/${addCourseForm.tab_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE2.src = Tabimage
                ddCourseForm.tab_image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.tab_image"
                          accept="image/* , String"
                           
                         
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Header image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="`https://api.lmitac.com/${addCourseForm.header_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                    addCourseForm.header_image=''
                    ">
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1"> </b-card-text>
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        
                      >
                        <b-form-file
                          ref="refInputEl"
                          
                          v-model="addCourseForm.header_image"
                          accept="image/* , String"
                          placeholder="Choose file"
                           
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">Og Graph Image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              > <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewE3"
                    :src="`https://api.lmitac.com/${addCourseForm.og_image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                class="rounded ml-1 mt-2 text-center"
                @click="refPreviewE3.src = Tabimage
                addCourseForm.og_image=''
                ">
                Remove Image
              </b-button>
            </div>
            <b-media-body>
              <b-card-text class="mt-1"> </b-card-text>
              <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Og Graph Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image"
                        
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="addCourseForm.og_image"
                          accept="image/* , String"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_og_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
         

         
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive, computed } from "@vue/composition-api";
import { useRouter } from "@core/utils/utils";
import Vue from "vue";
import store from "@/store";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const categoriesList = ref([]);
    const { route } = useRouter();
     const validLink = ref(true);
    const checkLink = (link) => {
      const formData = new FormData();

      formData.append("slug", link);
      store.dispatch("courses/checkLink", formData).then((response) => {
        console.log("lin", response.data.valid);
        validLink.value = response.data.valid;
      });
    };
    const id = route.value.params.id;
    store.dispatch("othercourses/GetCourse", { id }).then((response) => {
      store.commit("othercourses/GET_COURSE", response?.data.data);
    });
   const removeSpace = (target) => {
    
 addCourseForm.value.link_id= target.toLowerCase() // LowerCase
        .replace(/\s+/g, "-") // space to -
        .replace(/&/g, ``) // & to and
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, ``)
}
    store.dispatch("categories/AllCategory").then((response) => {
      console.log("response", response.data.data);
      categoriesList.value = response.data.data;
    });
    const CourseForm = computed(() => store.state.othercourses.courses);
    //  console.log("CourseForm",CourseForm)
    const addCourseForm = reactive(CourseForm);
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
         if (validLink.value == true) {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("is_free", addCourseForm.value.is_free);
          formData.append("name", addCourseForm.value.name);
          formData.append("header_image", addCourseForm.value.header_image);
          formData.append("name", addCourseForm.value.name);
          formData.append("link_id", addCourseForm.value.link_id);
          formData.append("tab_image", addCourseForm.value.tab_image);
          formData.append(" header_image", addCourseForm.value.header_image);
          formData.append("alt_tab_image", addCourseForm.value.alt_tab_image);
          formData.append(
            "alt_header_image",
            addCourseForm.value.alt_header_image
          );
          formData.append("duration", addCourseForm.value.duration);
          formData.append(" video_link", addCourseForm.value.video_link);
          formData.append("price", addCourseForm.value.price);
          formData.append("og_image", addCourseForm.value.og_image);
      formData.append("alt_og_image", addCourseForm.value.alt_og_image)
          formData.append("_method", "put");
          console.log("formData", formData);
          store
            .dispatch("othercourses/UpdateCourse", { id, formData })
            .then((response) => {
              Vue.swal({
                title: "Course Updated ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
       } else {
        Vue.swal({
          title: "",
          text: `Please Enter Valid Link`,
          icon: "error",
          confirmButtonText: "ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    };
    const refInputE3 = ref(null);

    const refPreviewE3 = ref(null);
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      (base64) => {
        console.log("dd");
        refPreviewE3.value.src = base64;
      }
    );
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      categoriesList,
      id,
      CourseForm,
      removeSpace,
      refInputE3,
      refPreviewE3,
      inputImageRendererTab3,
      validLink,
      checkLink,

      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
